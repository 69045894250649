.video-container {
    display: flex;
    height: 90vh;
    background-color: #080d1a;
}

.video-container iframe {
    width: 100%;
    height: 100%;
    border: none;
}