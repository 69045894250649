.copyright {
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white;
    font-size: 16px;
    /*font-weight: bold;*/
    /*text-align: center;*/
}