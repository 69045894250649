.production-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 135vh;
    background-color: #080d1a;
    justify-content: center;
    align-items: center;
    /*border: 1px solid red;*/
}

.production-container h1 {
    color: white;
    margin-top: 75px;
    /*margin-bottom: -10px;*/
    letter-spacing: 4px;
    font-size: 36px;
    /*border: 1px solid yellow;*/
}

.production {
    width: 80%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 50%);
    grid-gap: 0;
    grid-auto-rows: minmax(100px, auto);
    /*border: 1px solid green;*/
}

.prodtype {
    display: flex;
    flex-direction: column;
    /*border: 1px solid deeppink;*/
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
}

.prodtype img {
    width: 450px;
    border-radius: 6px;
    /*margin: 0;*/
    /*margin: auto;*/
}

.prodtype span {
    margin-top: 20px;
    margin-bottom: 20px;
    color: white;
    font-size: 26px;
    letter-spacing: 2px;
    font-weight: 600;
    width: 70%;
    /*margin: 0;*/
    /*font-weight: bold;*/
    /*margin-bottom: -px;*/
}

@media only screen and (max-width: 1024px) {
    .production-container {
        height: fit-content;
    }

    .production-container h1 {
        margin-top: 15px;
        margin-bottom: 25px;
        letter-spacing: 2px;
    }

    .production {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .prodtype {
        text-align: center;
        margin-bottom: 25px;
        margin-top: 25px;
    }

    .prodtype img {
        width: 90vw;
    }

    .prodtype span {
        margin-top: 18px;
        width: 100%;
    }
}