.products-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 70vh;
    background-color: #080d1a;
    justify-content: space-evenly;
    align-items: center;
    /*box-shadow: 0 40px 40px 125px #080d1a;*/
}

.products-container h1 {
    color: white;
    letter-spacing: 4px;
    margin-top: 85px;
    /*margin-top: auto;*/
    margin-bottom: 35px;
    font-size: 36px;
}

.products {
    display: flex;
    /*align-items: center;*/
    justify-content: center;
    width: 100%;
    height: 100%;
}

.wires {
    display: flex;
    flex-direction: column;
    text-align: center;
    /*justify-content: center;*/
    align-items: center;
    width: 30%;
}

.wires img {
    /*height: 50%;*/
    width: 300px;
    border-radius: 6px;
    margin-bottom: 20px;
}

.wires span:not(.span-header) {
    color: white;
    font-size: 20px;
    color: gray;
}

.span-header {
    font-weight: bold;
    font-size: 24px;
    color: white;
    margin-bottom: 15px;
}

@media only screen and (max-width: 1024px) {
    .products-container {
        height: fit-content;
        /*width: 100%;*/
    }

    .products-container h1 {
        margin-top: 40px;
    }

    .products {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .wires {
        margin-bottom: 40px;
        width: 95%;
    }
}