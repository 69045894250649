.contacts-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #080d1a;
}

.contacts-container h1 {
    color: white;
    font-size: 36px;
}

.contacts {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 75px;
}

.contact {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.contact h2 {
    color: white;
    font-size: 30px;
}

.contact span {
    font-size: 20px;
    color: white;
    margin-top: 4px;
    margin-bottom: 4px;
}

@media only screen and (max-width: 1024px) {
    .contacts {
        display: flex;
        flex-direction: column;
    }
}